import { Button, Card } from "pixel-retroui";
import { useEffect, useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
const BackgroundImages = [
    '/bg0.png',
    '/bg1.png',
    '/bg2.png',
    '/bg3.png',
];

declare global {
    interface Window {
        ym:any;
    }
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            ru: {
                translation: {
                    "officialwebsite": "Официальный сайт African Dawn",
                    "hero_lower": "Ветер свободы развеялся над Африкой",
                    "downloadlauncher": "Скачать лаунчер",
                    "anotherways": "Другие способы",
                    "about": "О модификации",
                    "why": "Зачем нужен лаунчер",
                    "modinfo": "События мода начинаются 30 сентября 2022 года: в Буркина-Фасо происходит военный переворот — к власти приходит капитан Ибрагим Траоре.\n\nВ первые три года главной целью игрока является искоренение терроризма в Сахеле. После неминуемой победы над террором начинается холодная война между двумя блоками — Альянсом государств Сахеля (АГС) и Экономическим сообществом западноафриканских государств (ЭКОВАС).\n\nПанафриканисты из АГС, при поддержке России и ее Африканского корпуса МО РФ, выступают против неоколониальных держав — Франции и США, а также их союзников из ЭКОВАС.\n\nВ ходе игры у игроков будет возможность вмешаться в ряд вооруженных конфликтов для укрепления своих стартовых позиций перед Великой Африканской Войной, которая даст возможность решить накопившиеся за долгие годы континентальные проблемы.",
                    "whyinfo": "После блокировки нашей модификации в Steam Workshop со стороны Paradox Interactive, нашей командой было принято решение создать лаунчер, который будет одинаково удобен для пользователей лицензионной и пиратских версий игры.\n\nПри помощи лаунчера можно за пару нажатий установить модификацию, а также устанавливать обновления и заходить в мод без использования стандартного лаунчера игры.\n\nЛаунчер надежен и безопасен, так как его исходный код находится в открытом доступе и он разрабатывается командой самой модификации.\n\nБлагодаря лаунчеру, вы можете больше не ожидать перезаливов мода в Steam Workshop, а сразу обновлять его в один клик.",
                    "typesofinstall": "Варианты установки",
                    "launcher": "Лаунчер African Dawn",
                    "launcherinfo": "Лаунчер, разработанный командой\n African Dawn позволяет\n одним кликом производить\n установку и обновление модификации,\n а также запускать игру сразу с модом.",
                    "github": "Репозиторий GitHub",
                    "open": "Открыть",
                    "autoinstall": "BAT-автоустановщик",
                    "download": "Скачать",
                    "workshop": "Steam перезаливы",
                    "autoinstallinfo": "Автоустановщик, написанный на Batch\nУстановщик использует публичный\n Github модификации в качестве\nисточника.\n\nУстарел и не будет обновляться.",
                    "workshopinfo": "Несмотря на блокировки в Steam Workshop,\nмы стараемся периодически\nперезаливать мод в Steam\n\nК сожалению, ссылка на\n Workshop не всегда работает.",
                    "githubinfo": "У модификации имеется свой\nпубличный репозиторий в Github.\n\nПродвинутые пользователи могут\n настроить автообновление через Github\n Desktop или любой другой клиент."
                }
            },
            en: {
                translation: {
                    "officialwebsite": "African Dawn Official Site",
                    "hero_lower": "The winds of liberty have blown over Africa",
                    "downloadlauncher": "Download Launcher",
                    "anotherways": "Other methods",
                    "about": "About modification",
                    "why": "Why you need a Launcher",
                    "modinfo": "The events of the mod begin on September 30, 2022: there is a military coup in Burkina Faso - Captain Ibrahim Traoré comes to power.\n\nIn the first three years, the main goal of the player is to eradicate terrorism in the Sahel. After the inevitable victory over terrorism, a cold war begins between two blocs - the Alliance of Sahel States (ASCS) and the Economic Community of West African States (ECOWAS).\n\nThe Pan-Africanists from the ASCS, supported by Russia and its African Corps of the Defense Ministry, oppose the neo-colonial powers - France and the United States, as well as their allies from ECOWAS.\n\nIn the course of the game, players will have the opportunity to intervene in a number of armed conflicts to strengthen their starting position before the Great African War, which will provide an opportunity to solve the continental problems that have accumulated over the years.",
                    "whyinfo": "After our modification was blocked in Steam Workshop by Paradox Interactive, our team decided to create a launcher, which will be equally convenient for users of licensed and pirate versions of the game.\n\nThe launcher allows you to install the modification in a couple of clicks, as well as install updates and enter the mod without using the standard game launcher. The Launcher is safe and secure, as its source code is publicly available and it is developed by the team of the modification itself.\n\n Thanks to the Launcher, you can no longer wait for the re-installation of mods in Steam Workshop, and immediately update it in one click.",
                    "typesofinstall": "Installation methods",
                    "launcher": "African Dawn Launcher",
                    "launcherinfo": "The launcher, developed by the\n African Dawn team, allows you to\n install and update the modification\n with one click, and also launch the game with the mod immediately.",
                    "github": "GitHub Repository",
                    "open": "Open",
                    "autoinstall": "BAT Autoinstaller",
                    "download": "Download",
                    "workshop": "Steam Reuploads",
                    "autoinstallinfo": "The autoinstaller, written in Batch\n uses the public modification GitHub\n as a source.\n\nIt is outdated and will not be updated.",
                    "workshopinfo": "Despite the blocks in the Steam Workshop,\n we try to periodically reupload the mod to Steam\n\nUnfortunately, the link to the\n Workshop does not always work.",
                    "githubinfo": "The modification has its own\n public repository on Github.\n\nAdvanced users can set up auto-updates through Github\n Desktop or any other client."
                }
            },
            fr: {
                translation: {
                    "officialwebsite": "Site officiel d'African Dawn",
                    "hero_lower": "Les vents de la liberté ont soufflé sur l'Afrique",
                    "downloadlauncher": "Télécharger le lanceur",
                    "anotherways": "Autres méthodes",
                    "about": "À propos de la modification",
                    "why": "Pourquoi vous avez besoin d'un lanceur",
                    "modinfo": "Les événements du mod commencent le 30 septembre 2022 : il y a un coup d'état militaire au Burkina Faso - le Capitaine Ibrahim Traoré prend le pouvoir.\n\nDans les trois premières années, l'objectif principal du joueur est d'éradiquer le terrorisme au Sahel. Après l'inévitable victoire sur le terrorisme, une guerre froide commence entre deux blocs - l'Alliance des États du Sahel (ASCS) et la Communauté Économique des États de l'Afrique de l'Ouest (CEDEAO).\n\nLes pan-africanistes de l'ASCS, soutenus par la Russie et son Corps africain du ministère de la Défense, s'opposent aux puissances néocoloniales - la France et les États-Unis, ainsi qu'à leurs alliés de la CEDEAO.\n\nAu cours du jeu, les joueurs auront l'opportunité d'intervenir dans un certain nombre de conflits armés pour renforcer leur position de départ avant la Grande Guerre Africaine, ce qui donnera l'opportunité de résoudre les problèmes continentaux qui se sont accumulés au fil des ans.",
                    "whyinfo": "Après que notre modification a été bloquée dans le Steam Workshop par Paradox Interactive, notre équipe a décidé de créer un lanceur, qui sera également pratique pour les utilisateurs de versions sous licence et pirates du jeu.\n\nLe lanceur permet d'installer la modification en quelques clics, ainsi que d'installer des mises à jour et d'entrer dans le mod sans utiliser le lanceur de jeu standard. Le lanceur est sûr et sécurisé, car son code source est publiquement disponible et il est développé par l'équipe de la modification elle-même.\n\n Grâce au lanceur, vous n'avez plus à attendre la réinstallation des mods dans le Steam Workshop, et à le mettre à jour immédiatement en un clic.",
                    "typesofinstall": "Méthodes d'installation",
                    "launcher": "Lanceur African Dawn",
                    "launcherinfo": "Le lanceur, développé par l'équipe\n African Dawn, permet d'installer et de mettre à jour\n la modification en un clic\n, et aussi de lancer le jeu avec le mod immédiatement.",
                    "github": "Dépôt GitHub",
                    "open": "Ouvrir",
                    "autoinstall": "BAT Autoinstaller",
                    "download": "Télécharger",
                    "workshop": "Réuploads Steam",
                    "autoinstallinfo": "L'autoinstaller, écrit en Batch\n utilise le GitHub public de modification\n comme source.\n\nIl est obsolète et ne sera pas mis à jour.",
                    "workshopinfo": "Malgré les blocages dans le Steam Workshop,\n nous essayons de réuploader périodiquement le mod sur Steam\n\nMalheureusement, le lien vers le\n Workshop ne fonctionne pas toujours.",
                    "githubinfo": "La modification a son propre\n dépôt public sur Github.\n\nLes utilisateurs avancés peuvent\n configurer des mises à jour automatiques via Github\n Desktop ou tout autre client."
                }
            }
        },
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function MainPage() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();
    const [nextIndex, setNextIndex] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(BackgroundImages[0]);

    

    function handleDownloadLauncher() {
        window.ym(97920804,'reachGoal','launcherdownload');
        window.open('https://africandawn.online/download/setup.exe', '_blank');
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAnimate(true);
            setCurrentIndex((currentIndex + 1) % BackgroundImages.length);
            setNextIndex((nextIndex + 1) % BackgroundImages.length);
            setTimeout(() => {
                setBackgroundImage(BackgroundImages[currentIndex]);
                setAnimate(false);
            }, 1000);
        }, 8000);
        return () => clearInterval(intervalId);
    }, [currentIndex, nextIndex]);

    const handleScrollToInstall = () => {
        const element = document.getElementById('typesofinstall-div');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="max-h-screen overflow-y-auto scrollbar-pixel select-none h-screen">
            <header className="w-full px-8 py-4 flex items-center justify-between sticky top-0">
                <div>
                    {/* Замените "путь_к_логотипу" на актуальный путь к файлу */}
                    <img src="/logo192.png" alt="Логотип" className="h-12" />
                </div>
                <div className="flex space-x-4">
                    <a href="https://africandawn.online/discord" target="_blank" rel="noopener noreferrer">
                        <img src="/discordo.png" alt="Discord" className="h-5 w-5" />
                    </a>
                    <a href="https://africandawn.online/vk" target="_blank" rel="noopener noreferrer">
                        <img src="/vk.png" alt="VK" className="h-5 w-5" />
                    </a>
                    <a href="https://africandawn.online/github" target="_blank" rel="noopener noreferrer">
                        <img src="/github.png" alt="GitHub" className="h-5 w-5" />
                    </a>
                </div>
            </header>
            <div className="flex flex-col items-center justify-center min-h-screen bg-cover scroll-snap-align-center scroll-snap-stop">
                <div
                    className={`bg-hero ${animate ? 'fade-out' : 'fade-in'}`}
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: animate ? 0 : 1,
                        transition: 'opacity 1s',
                    }}
                ></div>
                <h1 className="text-6xl font-minecraft mb-4 text-center">{t('officialwebsite')}</h1>
                <h1 className="text-2xl font-minecraft mb-4 text-center">{t('hero_lower')}</h1>
                <div className="mt-8 flex gap-4 ">
                    <Button
                        bg="white"
                        textColor="black"
                        borderColor="gray"
                        shadow="gray"
                        className="mr-2"
                        //onClick={() => window.open('https://github.com/The-African-Dawn/mod-public/releases/latest/download/AfricanDawn_1.1.0.0.zip', '_blank')}
                        onClick={handleDownloadLauncher}
                    >
                        {t('downloadlauncher')}
                    </Button>
                    <Button
                        bg="black"
                        textColor="white"
                        borderColor="white"
                        shadow="white"
                        className="mr-2"
                        onClick={handleScrollToInstall}
                    >
                        {t('anotherways')}
                    </Button>
                </div>
            </div>
            <div className="flex flex-col items-center min-h-screen bg-black bg-[linear-gradient(to_right,rgba(255,255,255,0.06)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,255,255,0.12)_1px,transparent_1px)] bg-[size:24px_24px] scroll-snap-align-center scroll-snap-stop">
                <h1 className="text-3xl mt-20 font-minecraft text-center">{t('about')}</h1>
                <div className="flex justify-center mt-20">
                    <div className="w-full md:w-3/4 xl:w-3/4 px-4 flex flex-wrap justify-center ">
                        <div className="w-full md:w-1/2 xl:w-1/2 text-white my-8">
                            <p className="text-lg whitespace-pre-line">
                                {t('modinfo')}
                            </p>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/2 p-10 flex flex-col justify-center">
                            <img
                                className="max-h-full w-full md:w-auto xl:w-auto lg:max-w-lg lg:max-h-lg xl:max-w-xl xl:max-h-xl"
                                src="/screen1.png"
                                alt="Скриншот 1"
                            />
                            <div className="h-16"></div>
                            <img
                                className="max-h-full w-full md:w-auto xl:w-auto lg:max-w-lg lg:max-h-lg xl:max-w-xl xl:max-h-xl"
                                src="/screen2.png"
                                alt="Скриншот 2"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center min-h-screen bg-black bg-[linear-gradient(to_right,rgba(255,255,255,0.06)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,255,255,0.12)_1px,transparent_1px)] bg-[size:24px_24px] scroll-snap-align-center scroll-snap-stop">
                <h1 className="text-3xl mt-20 font-minecraft text-center">{t('why')}</h1>
                <div className="flex justify-center mt-20">
                    <div className="w-full md:w-3/4 xl:w-3/4 px-4 flex flex-wrap justify-center ">
                        <div className="w-full md:w-1/2 xl:w-1/2 p-10 flex flex-col justify-center">
                            <img
                                className="max-h-full w-full md:w-auto xl:w-auto lg:max-w-lg lg:max-h-lg xl:max-w-xl xl:max-h-xl"
                                src="/screen3.png"
                                alt="Скриншот 1"
                            />
                            <div className="h-16"></div>
                            <img
                                className="max-h-full w-full md:w-auto xl:w-auto lg:max-w-lg lg:max-h-lg xl:max-w-xl xl:max-h-xl"
                                src="/screen4.png"
                                alt="Скриншот 2"
                            />
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/2 text-white my-8">
                            <p className="text-lg whitespace-pre-line">
                                {t('whyinfo')}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div id="typesofinstall-div" className="flex flex-col items-center min-h-screen bg-black bg-[linear-gradient(to_right,rgba(255,255,255,0.06)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,255,255,0.12)_1px,transparent_1px)] bg-[size:24px_24px] scroll-snap-align-center scroll-snap-stop ">
                <h1 className="text-3xl mt-20 font-minecraft text-center">{t('typesofinstall')} </h1>
                <div className="mt-20 flex gap-4">
                    <div className="grid grid-cols-2 gap-4">
                        <Card
                            bg="black"
                            textColor="white"
                            borderColor="white"
                            shadowColor="white"
                            className="p-7 text-center whitespace-pre-line"
                        >
                            <h2 className="text-2xl">{t('launcher')}</h2>
                            <p className="mt-5">{t('launcherinfo')}</p>
                            <Button
                                bg="white"
                                textColor="black"
                                borderColor="white"
                                shadow="white"
                                className="mt-11"
                                onClick={handleDownloadLauncher}
                            >
                                {t('downloadlauncher')}
                            </Button>
                        </Card>
                        <Card
                            bg="black"
                            textColor="white"
                            borderColor="gray"
                            shadowColor="gray"
                            className="p-7 text-center whitespace-pre-line"
                        >
                            <h2 className="text-2xl">{t('github')}</h2>
                            <p className="mt-5">{t('githubinfo')}</p>
                            <Button
                                bg="black"
                                textColor="white"
                                borderColor="gray"
                                shadow="gray"
                                className="mt-5"
                                onClick={() => window.open('https://africandawn.online/github', '_blank')}
                            >
                                {t('open')}
                            </Button>
                        </Card>
                        <Card
                            bg="black"
                            textColor="white"
                            borderColor="gray"
                            shadowColor="gray"
                            className="p-7 text-center whitespace-pre-line"
                        >
                            <h2 className="text-2xl">{t('autoinstall')}</h2>
                            <p className="mt-5">{t('autoinstallinfo')}</p>
                            <Button
                                bg="black"
                                textColor="white"
                                borderColor="gray"
                                shadow="gray"
                                className="mt-5"
                                onClick={() => window.open('/AfricaDawnInstaller.bat', '_blank')}
                            >
                                {t('download')}
                            </Button>
                        </Card>
                        <Card
                            bg="black"
                            textColor="white"
                            borderColor="gray"
                            shadowColor="gray"
                            className="p-7 text-center whitespace-pre-line"
                        >
                            <h2 className="text-2xl">{t('workshop')}</h2>
                            <p className="mt-5">{t('workshopinfo')}</p>
                            <Button
                                bg="black"
                                textColor="white"
                                borderColor="gray"
                                shadow="gray"
                                className="mt-5"
                                onClick={() => window.open('https://africandawn.online/steam', '_blank')}
                            >
                                {t('open')}
                            </Button>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center h-24 bg-black bg-[linear-gradient(to_right,rgba(255,255,255,0.06)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,255,255,0.12)_1px,transparent_1px)] bg-[size:24px_24px] scroll-snap-align-center scroll-snap-stop">
            </div>
            <div className="flex flex-col items-center h-24 bg-black scroll-snap-stop justify-center">
                <p className="text-center text-gray-400">African Dawn Team © {(new Date().getFullYear())}.</p>
            </div>
        </div>
    );
}

export default MainPage;